import { useState } from 'react';
import '../styles/testimonials/new.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NextArrow from "../arrows/PrevArrow";
import PrevArrow from "../arrows/NextArrow";
import Slider from 'react-slick';

const TestimonialsOne = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settingsMobile = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => (
      <div
        style={{
          width: "100%",
          color: "blue",
          position: "relative",
          placeSelf: "center"
        }}
      >
        <div style={{ opacity: "0", cursor: "pointer" }}>{i}</div>
        <span
          style={{
            position: "absolute",
            right: "0",
            cursor: "pointer",
            backgroundColor: i === currentSlide ? "#0781fe" : "#343638",
            height: "1.5rem",
            width: "1.5rem",
            borderRadius: "100%",
          }}
        ></span>
      </div>
    ),
  };

  const reviews = [
    // {
    //     name: "Sir Danthel",
    //     image: "https://user-images.trustpilot.com/62f64d015f9959001469c9f1/73x73.png",
    //     title: "Trader",
    //     review: "At this point I'm just in awe at this platform. From the emails keeping in touch and even the guidance. What a wonderful opportunity to help my family and become the earner I know I can be. 5 stars"
    // },
    {
        name: "Louis can Nuekerk",
        image: "https://user-images.trustpilot.com/62fb7908e7527700131ee873/73x73.png",
        title: "User",
        review: "Incredible company, I've been trading for a while with them, had two quick withrawals and fee refund in less than 48 hours. I absolutely recommend the firm"
    },
    {
        name: "Peter M",
        image: "https://user-images.trustpilot.com/630399601be97400121ac68d/73x73.png",
        title: "User",
        review: "This is an amazing platform which gives you opportunity to achieve their goals and become successful trade with the support of finances."
    },
    {
        name: "Jakub Szulc",
        image: "https://user-images.trustpilot.com/6304aa40cc230c001374b256/73x73.png",
        title: "Trader",
        review: "Great company, no issues with payouts, great customer support. Highly recommend for experienced traders."
    },
    {
        name: "Jeremy Harper",
        image: "https://user-images.trustpilot.com/62f7f8c90a1282001276670b/73x73.png",
        title: "Trader",
        review: "Legitimate! Thank you for the opportunity, nothing out there that can compare"
    },
   ]

  return (
    <>
  <div className="testimonials__new__container">
    {/* <div className="testimonials__new__wrapper"> */}

    <div className="testimonials__new__extra">
      <Slider {...settingsMobile} afterChange={(e) => setCurrentSlide(e)} className='testimonials__one__wrapper' > 
      {reviews.map(review => (
        <div className="testimonials__new__content">
           <div className="testimonials__new__text">
            <h1 className="testimonials__new__title">
            {review.review}
             </h1>
        <span className="testimonials__new__user">
          <p className="testimonial__user__name"> {review.name}</p>
          <p className="testimonial__user__about">{review.title}</p>
        </span>
        </div>
        <img className="testimonials__new__image" src={review.image}></img>
      </div>
        ))}
</Slider>
      {/* </div> */}
      </div>
  </div>
</>
  )
}


export default TestimonialsOne